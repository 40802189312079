import React, { useEffect, useState } from "react";

import { loadUser, ApplicationState, onLoading } from "../store";
import { useSelector, useDispatch } from "react-redux";
import ImageUploading from "react-images-uploading";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL, DISTRO_URL,PINATA_JWT, getImage } from "../utils";
import { sha256 } from "js-sha256";
import loadingIMG from "../assets/images/loading.svg";
import check_0 from "../assets/images/check_0.png";
import check_1 from "../assets/images/check_1.png";
import avatarMask from "../assets/images/avatar_mask.png";

//@ts-ignore
import mergeImages from "merge-images";

//@ts-ignore
import { NFTStorage } from "nft.storage/dist/bundle.esm.min.js";
import { NFT_STORAGE_API_KEY } from "../utils/nftstorage";
import { toast } from "react-toastify";

import useUrlInput from "use-url-input";

// @ts-ignore
import resizebase64 from "resize-base64";

export default function ProfileForm({
                                      type,
                                      userno,
                                      useremail,
                                      usertoken,
                                      register,
                                    }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state: ApplicationState) => state.userReducer);

  const [loadingImage, setLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [profiles, setProfiles] = useState([]) as any;

  const [image, setImage] = useState("");
  const [imageMask, setImageMask] = useState("");

  const [bio, setBio] = useState("");

  const [webUrl, setWebUrl] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [spotifyUrl, setSpotifyUrl] = useState("");
  const [appleUrl, setAppleUrl] = useState("");
  const [tidalUrl, setTidalUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  useUrlInput(webUrl, setWebUrl);
  useUrlInput(facebookUrl, setFacebookUrl);
  useUrlInput(spotifyUrl, setSpotifyUrl);
  useUrlInput(appleUrl, setAppleUrl);
  useUrlInput(tidalUrl, setTidalUrl);
  useUrlInput(instagramUrl, setInstagramUrl);

  const [aggree, setAggree] = useState(false);

  const [showTerms, setShowTerms] = useState(false);

  useEffect(() => {
    setBio("");
    setWebUrl("");
    setFacebookUrl("");
    setSpotifyUrl("");
    setImage("");
    setImageMask("");
    setProfiles([]);

    if (typeof user.email === "undefined") {
    } else {
      setName(user.name);
      setBio(user.bio);
      setWebUrl(user.web_url);
      setFacebookUrl(user.facebook_url);
      setSpotifyUrl(user.spotify_url);

      setAppleUrl(user.apple_url);
      setTidalUrl(user.tidal_url);
      setInstagramUrl(user.instagram_url);
      setImage(user.image);
      setImageMask(user.image_default);
      if (user.image !== "") {
        setProfiles([{ data_url: String(getImage(user.image, "th")) }]);
      }
    }
  }, [user]);

  useEffect(() => {
    if (register) {
      loadUserDataDistro();
    }
  }, []);

  const loginUser = async (id: string) => {
    await Promise.all([dispatch(onLoading(true)), dispatch(loadUser(id))]);

    navigate("/home");
    return dispatch(onLoading(false));
  };

  const loadUserDataDistro = async () => {
    try {
      // let res = await axios({
      //   url: `${DISTRO_URL}/getUserDetails.php?userno=${userno}&useremail=${useremail}&token=${usertoken}&cache=${Date.now()}`,
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // });
      let res = {status: 200, data: {"Firstname":"John","Surname":"Doe","Country":"Panama"}}; // mock data
      if (res.status === 200) {
        if (typeof res.data.Firstname !== "undefined") {
          setName(res.data.Firstname + " " + res.data.Surname);
          setBio("");
          setWebUrl("");
          setFacebookUrl("");
          setSpotifyUrl("");
          setAppleUrl("");
          setTidalUrl("");
          setInstagramUrl("");
          setImage("");
          setImageMask("");
          setProfiles([]);
        }
      } else {
      }
    } catch (err) {}
  };

  //@ts-ignore
  const onChangeProfile = (imageList, addUpdateIndex) => {
    setProfiles(imageList);

    if (imageList.length === 0) {
      setImage("");
      setImageMask("");
    }

    if (imageList.length > 0) {
      uploadPhoto(
          imageList[0].data_url,
          imageList[0].file.name,
          imageList[0].file
      );
    }
  };

  const dataURItoBlob = (dataURI: string): Blob => {
    // rozdělit dataURI na část MIME typu a část dat
    const splitDataURI = dataURI.split(",");

    // převést base64 datovou část na bajty
    const byteString = atob(splitDataURI[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    // získat MIME typ
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    // vytvořit blob
    return new Blob([int8Array], { type: mimeString });
  };

  const uploadPhoto = async (data: string, name: string, file: File) => {
    setLoadingImage(true);

    const img2: any = new Image();
    img2.src = data;
    await img2.decode();

    let imageMask = "";
    mergeImages(
        [
          { src: data, width: img2.width, height: img2.height },
          { src: avatarMask, width: img2.width, height: img2.height },
        ],
        { width: img2.width, height: img2.height }
    ).then(async (b64: any) => {
      imageMask = b64;

      try {
        const formData = new FormData();
        formData.append("file", file);
        const metadata = JSON.stringify({
          name: file.name,
        });
        formData.append("pinataMetadata", metadata);

        const options = JSON.stringify({
          cidVersion: 1,
        });
        formData.append("pinataOptions", options);

        const formDataImageMask = new FormData();
        formDataImageMask.append("file", dataURItoBlob(imageMask));
        const metadataImageMask = JSON.stringify({
          name: file.name+"_mask",
        });
        formDataImageMask.append("pinataMetadata", metadataImageMask);

        const res = await fetch(
            "https://api.pinata.cloud/pinning/pinFileToIPFS",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${PINATA_JWT}`,
              },
              body: formData,
            }
        );

        const resImageMask = await fetch(
            "https://api.pinata.cloud/pinning/pinFileToIPFS",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${PINATA_JWT}`,
              },
              body: formDataImageMask,
            }
        );
        const resData = await res.json();
        const resDataImageMask = await resImageMask.json();
        if (resData && resDataImageMask) {
          setImage(resData.IpfsHash);
          setImageMask(resDataImageMask.IpfsHash);

          setLoadingImage(false);
        } else {
          setLoadingImage(false);
          setProfiles([]);
          setImage("");
          setImageMask("");
        }

      } catch (error) {
        console.log(error);
        setLoadingImage(false);
        setProfiles([]);
        setImage("");
        setImageMask("");
      }
    });
  };

  const sendForm = async () => {
    setLoading(true);
    if (type === "register") {
      try {
        let res = await axios({
          url: `${BASE_URL}/api/v1/pressing-machine/register`,
          method: "POST",
          data: {
            id: userno,
            email: useremail,
            name: name,
            bio: bio,
            image: image,
            image_mask: imageMask,
            web_url:
                webUrl === "https://" || webUrl === "http://" ? "" : webUrl,
            facebook_url:
                facebookUrl === "https://" || facebookUrl === "http://"
                    ? ""
                    : facebookUrl,
            spotify_url:
                spotifyUrl === "https://" || spotifyUrl === "http://"
                    ? ""
                    : spotifyUrl,
            apple_url:
                appleUrl === "https://" || appleUrl === "http://" ? "" : appleUrl,
            tidal_url:
                tidalUrl === "https://" || tidalUrl === "http://" ? "" : tidalUrl,
            instagram_url:
                instagramUrl === "https://" || instagramUrl === "http://"
                    ? ""
                    : instagramUrl,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.status === 200 && res.data.status) {
          loginUser(res.data.payload.id);
        } else {
          // alert("Error");
          setLoading(false);
          toast.error(
              "There has been an issue while creating profile. Please try again later or contact us."
          );
        }

        return res.data;
      } catch (err) {
        // console.log(err);
        setLoading(false);
        toast.error(
            "There has been an issue while creating profile. Please try again later or contact us."
        );
      }
    } else if (type === "update") {
      try {
        let res = await axios({
          url: `${BASE_URL}/api/v1/pressing-machine/artist`,
          method: "POST",
          data: {
            id_hash: user.id,
            id: userno,
            email: useremail,
            name: name,
            bio: bio,
            image: image,
            image_mask: imageMask,
            web_url:
                webUrl === "https://" || webUrl === "http://" ? "" : webUrl,
            facebook_url:
                facebookUrl === "https://" || facebookUrl === "http://"
                    ? ""
                    : facebookUrl,
            spotify_url:
                spotifyUrl === "https://" || spotifyUrl === "http://"
                    ? ""
                    : spotifyUrl,
            apple_url:
                appleUrl === "https://" || appleUrl === "http://" ? "" : appleUrl,
            tidal_url:
                tidalUrl === "https://" || tidalUrl === "http://" ? "" : tidalUrl,
            instagram_url:
                instagramUrl === "https://" || instagramUrl === "http://"
                    ? ""
                    : instagramUrl,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.status === 200 && res.data.status) {
          loadUserData();
        } else {
          // alert("Error");
          setLoading(false);
          toast.error(
              "There has been an issue while updating profile. Please try again later or contact us."
          );
        }

        return res.data;
      } catch (err) {
        console.log(err);
        setLoading(false);
        toast.error(
            "There has been an issue while updating profile. Please try again later or contact us."
        );
      }
    }
  };

  const loadUserData = async () => {
    await Promise.all([dispatch(onLoading(true)), dispatch(loadUser(user.id))]);

    navigate("/home");
    setLoading(false);
    return dispatch(onLoading(false));
  };

  const Loader = () => {
    return (
        <div className="uploading-bar">
          <div className="uploading-bar-inner">
            <div className="uploading-bar-progress"></div>
          </div>
        </div>
    );
  };

  return (
      <div className="form">
        <div className="form-group">
          <label>
            <div>Your name *</div>
            <div className="help-tooltip">
              <div className="tooltip-box">
                <div className="tooltip-box-inner">
                  <h3>Showcase your unique identity</h3>
                  <p>
                    Enter your name in the "Your Name" field, which will serve as
                    the collection name for your NFTs. It's important to choose a
                    name that represents your artistic identity effectively since
                    the collection name cannot be readily changed once set. Your
                    collection name will be prominently displayed in marketplaces
                    and will help fans identify and connect with your NFTs easily.
                    Consider using your artist name or a distinctive name that
                    reflects your brand and resonates with your audience. Make
                    sure to double-check the accuracy before proceeding, as this
                    name will become an essential part of your NFT collection's
                    identity.
                  </p>
                </div>
              </div>
            </div>
          </label>
          <input
              type="text"
              className="form-control"
              placeholder="Your name or nickname"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
          />
        </div>

        <div className="form-group profile-image">
          <label>
            <div>Profile image</div>
            <div className="help-tooltip">
              <div className="tooltip-box">
                <div className="tooltip-box-inner">
                  <h3>Present yourself visually</h3>
                  <p>
                    Upload an artist profile image to showcase your unique
                    persona. While not mandatory, it is highly recommended to
                    include an image that represents you as an artist. This
                    profile image will be used within the Gram180 ecosystem and on
                    various marketplaces. It should be a photo or visual
                    representation of yourself rather than an artwork specific to
                    a track. Your profile image will help fans identify and
                    connect with you as an artist, so choose a high-quality image
                    that captures your style, personality, or brand. By providing
                    a visual representation of yourself, you can make a memorable
                    impression and strengthen your connection with your audience.
                  </p>
                </div>
              </div>
            </div>
          </label>
          <ImageUploading
              multiple
              value={profiles}
              onChange={onChangeProfile}
              maxNumber={1}
              dataURLKey="data_url"
          >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper-profile">
                  {imageList.length === 0 && (
                      <button
                          style={
                            isDragging
                                ? { color: "#7b3fe4", borderColor: "#7b3fe4" }
                                : undefined
                          }
                          onClick={onImageUpload}
                          {...dragProps}
                          className={"dragbox-profile"}
                      >
                        Click or drag to ad cover image
                        <small>Recommended size 600x600px</small>
                      </button>
                  )}

                  {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        {loadingImage && <Loader />}
                        <div
                            className="image-uploaded"
                            style={{
                              backgroundImage: `url(${image["data_url"]})`,
                              backgroundSize: "cover",
                              width: "100%",
                              height: "100%",
                            }}
                        ></div>
                        <div className="image-item__btn-wrapper-profile">
                          <button
                              onClick={() => onImageRemove(index)}
                              className="remove-ico"
                          >
                            REPLACE IMAGE
                          </button>
                        </div>
                      </div>
                  ))}
                </div>
            )}
          </ImageUploading>
        </div>

        <div className="form-group">
          <label>
            <div>Your bio</div>
            <div className="help-tooltip">
              <div className="tooltip-box">
                <div className="tooltip-box-inner">
                  <h3>Share your artistic journey</h3>
                  <p>
                    Craft a compelling artist biography that reflects your
                    creative journey and story. Although not mandatory, it is
                    highly recommended to provide a bio that will be visible
                    across the NFT world. Your bio offers an opportunity to
                    connect with fans, collectors, and potential buyers on a
                    deeper level. Use this space to describe your artistic
                    influences, achievements, musical style, or any other
                    information that adds context and personality to your work. A
                    well-crafted bio can engage and resonate with your audience,
                    fostering a stronger connection and appreciation for your
                    music and NFTs. Take the time to write a concise and engaging
                    bio that highlights your uniqueness as an artist and leaves a
                    lasting impression on those who discover your NFTs.
                  </p>
                </div>
              </div>
            </div>
          </label>
          <textarea
              className="form-control"
              placeholder="Your bio"
              value={bio}
              onChange={(e) => {
                setBio(e.target.value);
              }}
          />
        </div>

        <div className="form-group">
          <label>Your site</label>
          <input
              type="url"
              className="form-control"
              placeholder="https://"
              value={webUrl}
              onChange={(e) => {
                setWebUrl(e.target.value);
              }}
          />
        </div>

        <div className="form-group">
          <label>Facebook profile</label>
          <input
              type="url"
              className="form-control"
              placeholder="https://"
              value={facebookUrl}
              onChange={(e) => {
                setFacebookUrl(e.target.value);
              }}
          />
        </div>

        <div className="form-group">
          <label>Spotify profile</label>
          <input
              type="url"
              className="form-control"
              placeholder="https://"
              value={spotifyUrl}
              onChange={(e) => {
                setSpotifyUrl(e.target.value);
              }}
          />
        </div>

        <div className="form-group">
          <label>Apple Music profile</label>
          <input
              type="url"
              className="form-control"
              placeholder="https://"
              value={appleUrl}
              onChange={(e) => {
                setAppleUrl(e.target.value);
              }}
          />
        </div>

        <div className="form-group">
          <label>Tidal profile</label>
          <input
              type="url"
              className="form-control"
              placeholder="https://"
              value={tidalUrl}
              onChange={(e) => {
                setTidalUrl(e.target.value);
              }}
          />
        </div>

        <div className="form-group">
          <label>Instagram profile</label>
          <input
              type="url"
              className="form-control"
              placeholder="https://"
              value={instagramUrl}
              onChange={(e) => {
                setInstagramUrl(e.target.value);
              }}
          />
        </div>

        {register && (
            <div className="form-group check-box">
              <button onClick={() => setAggree(!aggree)}>
                {aggree ? (
                    <img src={check_1} alt="Agree" />
                ) : (
                    <img src={check_0} alt="Agree" />
                )}
                I agree with the{" "}
                <button onClick={() => setShowTerms(!showTerms)}>
                  terms of use
                </button>
                .
              </button>
            </div>
        )}

        {register && (
            <button
                type="submit"
                className="btn btn-primary"
                onClick={() => sendForm()}
                disabled={loadingImage || name === "" || !aggree}
            >
              Submit{" "}
              {loading && (
                  <img
                      src={loadingIMG}
                      alt="Loading"
                      width={20}
                      height={20}
                      className="loading-animation"
                      style={{ marginLeft: 8 }}
                  />
              )}
            </button>
        )}

        {!register && (
            <button
                type="submit"
                className="btn btn-primary"
                onClick={() => sendForm()}
                disabled={loadingImage || name === ""}
            >
              Submit{" "}
              {loading && (
                  <img
                      src={loadingIMG}
                      alt="Loading"
                      width={20}
                      height={20}
                      className="loading-animation"
                      style={{ marginLeft: 8 }}
                  />
              )}
            </button>
        )}

        <div
            className={showTerms ? "modal-window show" : "modal-window"}
            id="open-modal"
        >
          <div className="modal-content">
            <div className="modal-heading">
              <h1>Terms of use</h1>
              <button
                  onClick={() => setShowTerms(false)}
                  className="modal-close"
              ></button>
            </div>
            Podmínky použití platformy pro výrobu MP3 Děkujeme, že používáte naši
            platformu pro výrobu MP3. Níže jsou uvedeny podmínky, které regulují
            vaše používání této platformy. Před použitím platformy si prosím
            pozorně přečtěte následující podmínky: Akceptace podmínek Používáním
            této platformy pro výrobu MP3 vyjadřujete svůj souhlas s těmito
            podmínkami. Pokud s nimi nesouhlasíte, neměli byste pokračovat v
            používání platformy. Omezení použití Platforma je určena výhradně pro
            osobní a nekomerční účely. Nesmíte ji používat k nelegálním nebo
            neoprávněným účelům. Nesmíte distribuovat, modifikovat, prodávat nebo
            kopírovat jakoukoli část platformy bez našeho předchozího písemného
            souhlasu. Ochrana duševního vlastnictví Veškeré autorská práva,
            ochranné známky, patenty a další práva duševního vlastnictví
            související s touto platformou jsou naším výhradním vlastnictvím nebo
            jsou použity se svolením příslušných vlastníků. Neposkytujeme vám
            žádná práva ani licence na tato duševní vlastnictví. Odpovědnost
            Platforma je poskytována "tak, jak je", a my nezaručujeme její
            bezchybný provoz, přesnost, spolehlivost ani vhodnost pro konkrétní
            účel. Neseme omezenou odpovědnost za případné škody, které by vám
            mohly vzniknout v souvislosti s používáním platformy. Odkazy na třetí
            strany Platforma může obsahovat odkazy na webové stránky třetích stran
            nebo služby. Tyto odkazy jsou poskytovány pouze pro vaše pohodlí, a my
            nejsme odpovědni za obsah těchto stránek ani za jakékoli škody
            způsobené jejich používáním. Zrušení a přerušení Vy nebo my si můžeme
            kdykoli ukončit nebo přerušit vaše používání platformy bez udání
            důvodu. Pokud porušíte tyto podmínky, můžeme okamžitě ukončit vaše
            používání platformy. Změny podmínek
          </div>
        </div>
      </div>
  );
}
