import axios from "axios";
import { Dispatch } from "react";
import { BASE_URL } from "../../utils";
import {ApplicationState} from "../reducers";
export interface AccountsInterface {
  readonly type: "LOAD_ACCOUNTS";
  payload: any;
}

export type LoadAccounts = AccountsInterface;


export const loadAccounts = (distroId: bigint,email:string) => {
  return async (dispatch: Dispatch<AccountsInterface>) => {
    try {
      // const response = await axios.get<any>(
      //   `${BASE_URL}get_user_accounts.php?email=${email}&cache=${Date.now()}`
      // );
      // const response = await axios.get<any>(
      //     `https://api.gram180.com/get_user_accounts.php?email=romansimr11@gmail.com.dickhead1&cache=${Date.now()}`
      // );
      // console.log(user);
      const response = await axios.get<any>(
          `${BASE_URL}/api/v1/pressing-machine/user/artists/${distroId}`
      );
      if (!response) {
      } else {
        dispatch({
          type: "LOAD_ACCOUNTS",
          payload: response.data.payload,
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };
};
