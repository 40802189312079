import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { sha256 } from "js-sha256";

import "react-toastify/dist/ReactToastify.css";
import "./assets/css/bootstrap.scss";
import "./assets/css/fonts.scss";
import "./assets/css/style.scss";

import { Provider } from "react-redux";
import { store } from "./store";

import { ToastContainer } from "react-toastify";

import LoginPage from "./pages/LoginPage";
import InitPage from "./pages/InitPage";
import HomePage from "./pages/HomePage";
import MintsPage from "./pages/MintsPage";
import ProfilePage from "./pages/ProfilePage";
import AdminPage from "./pages/AdminPage";

import Iframe from "./components/Iframe";
import AccountsPage from "./pages/AccountsPage";

const widgetDivs = document.querySelectorAll(".gram180-widget");

// Inject our React App into each class
widgetDivs.forEach((div: any) => {
  const control = sha256(
    "gram180_" + div.dataset.useremail + "_" + div.dataset.userno + "_rva21spkY"
  );

  ReactDOM.render(
    <React.StrictMode>
      {control !== div.dataset.usertoken ? (
        <div style={{ padding: 16 }}>
          <div className="alert alert-purple" role="alert">
            <strong>Authorization error!</strong>An error occurred during login
            to the application. Please contact us.
          </div>
        </div>
      ) : (
        <>
          <Iframe />
          <Provider store={store}>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <LoginPage
                      userno={div.dataset.userno}
                      useremail={div.dataset.useremail}
                      usertoken={div.dataset.usertoken}
                    />
                  }
                >
                  <Route
                    index
                    element={
                      <LoginPage
                        userno={div.dataset.userno}
                        useremail={div.dataset.useremail}
                        usertoken={div.dataset.usertoken}
                      />
                    }
                  />
                </Route>
                <Route
                  path="*"
                  element={
                    <LoginPage
                      userno={div.dataset.userno}
                      useremail={div.dataset.useremail}
                      usertoken={div.dataset.usertoken}
                    />
                  }
                />

                <Route
                  path="login"
                  element={
                    <LoginPage
                      userno={div.dataset.userno}
                      useremail={div.dataset.useremail}
                      usertoken={div.dataset.usertoken}
                    />
                  }
                />
                <Route
                  path="init"
                  element={
                    <InitPage
                      userno={div.dataset.userno}
                      useremail={div.dataset.useremail}
                      usertoken={div.dataset.usertoken}
                    />
                  }
                />
                <Route
                  path="accounts-page"
                  element={
                    <AccountsPage
                      userno={div.dataset.userno}
                      useremail={div.dataset.useremail}
                      usertoken={div.dataset.usertoken}
                    />
                  }
                />
                <Route
                  path="home"
                  element={
                    <HomePage
                      userno={div.dataset.userno}
                      useremail={div.dataset.useremail}
                      usertoken={div.dataset.usertoken}
                    />
                  }
                />
                <Route
                  path="mints"
                  element={
                    <MintsPage
                      userno={div.dataset.userno}
                      useremail={div.dataset.useremail}
                      usertoken={div.dataset.usertoken}
                    />
                  }
                />
                <Route
                  path="mints/:open"
                  element={
                    <MintsPage
                      userno={div.dataset.userno}
                      useremail={div.dataset.useremail}
                      usertoken={div.dataset.usertoken}
                    />
                  }
                />
                <Route
                  path="profile"
                  element={
                    <ProfilePage
                      userno={div.dataset.userno}
                      useremail={div.dataset.useremail}
                      usertoken={div.dataset.usertoken}
                    />
                  }
                />
                <Route
                  path="admin"
                  element={
                    <AdminPage
                      userno={div.dataset.userno}
                      useremail={div.dataset.useremail}
                      usertoken={div.dataset.usertoken}
                    />
                  }
                />
              </Routes>
            </BrowserRouter>
            <ToastContainer hideProgressBar />
          </Provider>
        </>
      )}
    </React.StrictMode>,
    div
  );
});
