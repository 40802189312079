import React from "react";

import { loadUser, ApplicationState, onLoading } from "../store";
import { useSelector, useDispatch } from "react-redux";

import gram180logo from "../assets/images/gram_logo.svg";
import { WEB_URL } from "../utils";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="footer-logo">
          <img src={gram180logo} alt="Gram180" />
        </div>
        <p>
          Pressing Machine by{" "}
          <a href={WEB_URL} target="_blank" rel="noreferrer">
            gram180
          </a>
        </p>
        <p>App version 1.1</p>
      </footer>
      <svg width="0" height="0">
        <clipPath id="shape">
          <path d="m49.2746137 1.93883096 27.4507726 15.76418434c4.5015466 2.585108 7.2746137 7.3625845 7.2746137 12.5328004v31.5283686c0 5.1702159-2.7730671 9.9476924-7.2746137 12.5328004l-27.4507726 15.7641843c-4.5015466 2.585108-10.0476808 2.585108-14.5492274 0l-27.45077262-15.7641843c-4.50154661-2.585108-7.27461368-7.3625845-7.27461368-12.5328004v-31.5283686c0-5.1702159 2.77306707-9.9476924 7.27461368-12.5328004l27.45077262-15.76418434c4.5015466-2.58510795 10.0476808-2.58510795 14.5492274 0z" />
        </clipPath>
        <g clip-path="url(#a)">
          <path d="m0 0h84v92h-84z" fill="#fff" />
        </g>
      </svg>
    </>
  );
}
