import { combineReducers } from "redux";
import { UserReducer } from "./userReducer";
import { LoadingReducer } from "./loadingReducer";
import { AccountsReducer } from "./accountsReducer";

const rootReducer = combineReducers({
  loadingReducer: LoadingReducer,
  userReducer: UserReducer,
  accountsReducer: AccountsReducer,
});

export type ApplicationState = ReturnType<typeof rootReducer>;

export { rootReducer };
