import { LoadAccounts } from "../actions/accountsActions";

type AccountsState = {
  accounts: any;
  error: string | undefined;
};

const initialState = {
  accounts: [] as any[],
  error: undefined,
};

const AccountsReducer = (
  state: AccountsState = initialState,
  action: LoadAccounts
) => {
  switch (action.type) {
    case "LOAD_ACCOUNTS":
      return {
        ...state,
        accounts: action.payload,
      };

    default:
      return state;
  }
};

export { AccountsReducer };
