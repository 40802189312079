import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import ProfileForm from "../components/ProfileForm";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import { DISTRO_URL } from "../utils";
import { clearUser, onLoading } from "../store";

import icoBack from "../assets/images/angle-left.svg";
import icoLogout from "../assets/images/icoLogout.svg";

export default function InitPage({ userno, useremail, usertoken }: any) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    clearUserData();
    loadUserDataDistro();
  }, []);

  const clearUserData = async () => {
    await Promise.all([dispatch(onLoading(true)), dispatch(clearUser())]);

    return dispatch(onLoading(false));
  };

  const loadUserDataDistro = async () => {
    try {
      let res = await axios({
        url: `${DISTRO_URL}/getUserDetails.php?userno=${userno}&useremail=${useremail}&token=${usertoken}&cache=${Date.now()}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        if (typeof res.data.Firstname !== "undefined") {
          setName(res.data.Firstname + " " + res.data.Surname);
        }
      } else {
      }
    } catch (err) {}
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="header-buttons">
                <button
                  onClick={() => navigate("/accounts-page")}
                  className="btn-left"
                >
                  <img src={icoBack} alt="Log out" />
                  <span>Back</span>
                </button>
                <div className="divider" />

                <button onClick={() => navigate("/")} className="btn-right">
                  <span>Log out</span>
                  <img src={icoLogout} alt="Log out" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="container">
        <div className="row">
          <div className="col-sm-12">
            <div
              className="content content-main not-flex"
              style={{ position: "relative" }}
            >
              <h3 className="main-title">Complete artist profile</h3>

              <ProfileForm
                type={"register"}
                userno={userno}
                useremail={useremail}
                usertoken={usertoken}
                register
              />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}
