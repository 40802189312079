import React from "react";

import { loadUser, ApplicationState, onLoading } from "../store";
import { useSelector, useDispatch } from "react-redux";

export default function Alert({ type, title, desc }: any) {
  return (
    <div className={"alert alert-" + type} role="alert">
      <strong>{title}</strong>
      {desc}
    </div>
  );
}
