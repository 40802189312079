import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import ProfileForm from "../components/ProfileForm";

import { ApplicationState, onLoading, loadUser } from "../store";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  BASE_URL,
  DISTRO_URL,
  getImage,
  IPFS_URL,
  OPENSEA_URL,
  POLYGONSCAN_URL,
  WEB_URL,
} from "../utils";
import placeholderIMG from "../assets/images/placeholder.svg";
import icoDashboard from "../assets/images/icoDashboard.svg";
import icoLogout from "../assets/images/icoLogout.svg";

export default function InitPage({ userno, useremail, usertoken }: any) {
  const navigate = useNavigate();

  const { user } = useSelector((state: ApplicationState) => state.userReducer);

  const [loading, setLoading] = useState(false);
  const [mints, setMints] = useState([]);

  useEffect(() => {
    setLoading(true);

    loadData();
    checkStatus();
  }, []);

  const checkStatus = () => {
    setTimeout(() => {
      loadData();
    }, 10000);
  };

  const loadData = async () => {
    try {
      // const response = await axios.get<any>(
      //   `${BASE_URL}get_mints_admin.php?cache=${Date.now()}`
      // );
      const response = await axios.get<any>(
          `https://api.gram180.com/get_mints_admin.php?cache=${Date.now()}`
      );

      if (!response) {
        setLoading(false);
      } else {
        setMints(response.data);
        setLoading(false);
      }
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const getMintToken = (data: string) => {
    let value = JSON.parse(data)["logs"][0]["data"].slice(2);
    let size = value.length / 2;

    let half1 = value.substring(0, value.length / 2);
    let half2 = value.substring(value.length / 2);

    return parseInt(half1, 16);
  };

  const changeApproved = async (status: string, id: string) => {
    try {
      let res = await axios({
        url: `${BASE_URL}toggle_mint_approved.php?cache=${Date.now()}`,
        method: "POST",
        data: {
          token: user.id,
          id: id,
          status: status,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200 && res.data.status) {
        loadData();
      } else {
        // alert("Error");
        loadData();
      }

      return res.data;
    } catch (err) {
      loadData();
    }
  };

  if (
    useremail !== "vojta@vojtapohunek.com" &&
    useremail !== "romansimr11@gmail.com" &&
    useremail !== "b4rt11@gmail.com"
  ) {
    return null;
  }

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="header-buttons">
                <button onClick={() => navigate("/home")} className="btn-left">
                  <img src={icoDashboard} alt="My Dashboard" />
                  <span>My Dashboard</span>
                </button>
                <div className="divider" />

                <button onClick={() => navigate("/")} className="btn-right">
                  <span>Log out</span>
                  <img src={icoLogout} alt="Log out" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <h1 className="main-heading">{user.name}</h1>

      <div className="profile-photo-box">
        <img
          className="profile-photo"
          src={getImage(user.image, "th")}
          alt={user.name}
        />
      </div>

      <main className="container main-big">
        <div className="row">
          <div className="col-sm-12">
            <div className="content content-main">
              <div className="my-mints">
                {loading && (
                  <div style={{ minHeight: 600 }} className="placeholder-box">
                    <div>
                      <img
                        src={placeholderIMG}
                        alt="Loading"
                        width={416}
                        height={144}
                      />
                    </div>
                    <div style={{ marginTop: 44 }}>
                      <img
                        src={placeholderIMG}
                        alt="Loading"
                        width={416}
                        height={144}
                      />
                    </div>
                    <div style={{ marginTop: 44 }}>
                      <img
                        src={placeholderIMG}
                        alt="Loading"
                        width={416}
                        height={144}
                      />
                    </div>
                  </div>
                )}

                {!loading && (
                  <>
                    {mints.map((item: any) => (
                      <div className="mint-box" key={JSON.stringify(item)}>
                        {item.status === "0" && (
                          <>
                            {(item.approved === "0" ||
                              item.approved === "1") && (
                              <div className="mint-cover mint-pending">
                                <img
                                  src={getImage(
                                    item.type === "custom"
                                      ? item.image
                                      : item.image_original,
                                    "th"
                                  )}
                                  alt="Cover"
                                />
                              </div>
                            )}

                            {item.approved === "2" && (
                              <div className="mint-cover mint-fail">
                                <img
                                  src={getImage(
                                    item.type === "custom"
                                      ? item.image
                                      : item.image_original,
                                    "th"
                                  )}
                                  alt="Cover"
                                />
                              </div>
                            )}
                          </>
                        )}
                        {item.status === "1" && (
                          <div className="mint-cover mint-success">
                            <img
                              src={getImage(
                                item.type === "custom"
                                  ? item.image
                                  : item.image_original,
                                "th"
                              )}
                              alt="Cover"
                            />
                          </div>
                        )}
                        {item.status === "2" && (
                          <div className="mint-cover mint-fail">
                            <img
                              src={getImage(
                                item.type === "custom"
                                  ? item.image
                                  : item.image_original,
                                "th"
                              )}
                              alt="Cover"
                            />
                          </div>
                        )}

                        <div className="mint-content">
                          <div className="mint-name">{item.name}</div>
                          {item.status === "0" && (
                            <>
                              {item.approved === "1" && (
                                <div className="mint-pending-box">
                                  Mint in progress
                                </div>
                              )}

                              {item.approved === "0" && (
                                <div className="mint-pending-box">
                                  Waiting for approval
                                </div>
                              )}

                              {item.approved === "2" && (
                                <div className="mint-fail-box">Rejected</div>
                              )}
                            </>
                          )}
                          {item.status === "1" && (
                            <div className="mint-success-box">
                              Minting successful
                            </div>
                          )}
                          {item.status === "2" && (
                            <div className="mint-fail-box">
                              Minting has failed
                            </div>
                          )}
                          {item.metadata !== "" && (
                            <p>
                              <strong>Metadata:</strong>{" "}
                              <a
                                href={IPFS_URL + "" + item.metadata}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Open metadata
                              </a>
                            </p>
                          )}
                          {item.transaction !== "" && (
                            <p>
                              <strong>Transaction ID:</strong>{" "}
                              <a
                                href={
                                  POLYGONSCAN_URL + "/tx/" + item.transaction
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                Show on polygonscan.com
                              </a>
                            </p>
                          )}
                          <p>
                            <strong>Number of tokens:</strong>{" "}
                            {Number(item.tokens) - 1}
                          </p>
                          <p>
                            <strong>Price:</strong> {item.suggested_price} USDC
                          </p>
                          {item.image !== "" && (
                            <p>
                              <strong>Image:</strong>{" "}
                              <a
                                href={
                                  "https://nftstorage.link/ipfs/" + item.image
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.image}
                              </a>
                            </p>
                          )}
                          {item.image_original !== "" && (
                            <p>
                              <strong>Image original:</strong>{" "}
                              <a
                                href={item.image_original}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.image_original}
                              </a>
                            </p>
                          )}
                          {item.description !== "" && (
                            <p>
                              <strong>Description:</strong> {item.description}
                            </p>
                          )}
                          {item.release_date !== "" && (
                            <p>
                              <strong>Release date:</strong> {item.release_date}
                            </p>
                          )}

                          {item.genres !== "" && (
                            <p>
                              <strong>Genres:</strong>{" "}
                              {JSON.parse(item.genres).map((genre: any) => {
                                return genre + " / ";
                              })}
                            </p>
                          )}

                          {item.animation_url !== "" && (
                            <p>
                              <strong>Track:</strong>{" "}
                              <a
                                href={
                                  "https://nftstorage.link/ipfs/" +
                                  item.animation_url
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.animation_url}
                              </a>
                            </p>
                          )}

                          {item.animation_url_original !== "" && (
                            <p>
                              <strong>Track original:</strong>{" "}
                              <a
                                href={item.animation_url_original}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.animation_url_original}
                              </a>
                            </p>
                          )}

                          {item.status === "1" && (
                            <div className="mint-links">
                              <a
                                href={
                                  OPENSEA_URL +
                                  item.contract +
                                  "/" +
                                  getMintToken(item.transaction_data)
                                }
                                className="btn-opensea"
                                target="_blank"
                                rel="noreferrer"
                              >
                                OpenSea
                              </a>
                              <a
                                href={
                                  WEB_URL +
                                  "/artist/" +
                                  item.permalink +
                                  "/" +
                                  item.id
                                }
                                className="btn-gram180"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Gram180
                              </a>
                            </div>
                          )}
                          {item.approved === "0" && (
                            <>
                              <br />
                              <button
                                type="submit"
                                className="btn btn-approve"
                                onClick={() => changeApproved("1", item.id)}
                              >
                                Approve
                              </button>{" "}
                              <button
                                type="submit"
                                className="btn btn-approve"
                                onClick={() => changeApproved("2", item.id)}
                              >
                                Reject
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
