export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const DISTRO_URL = process.env.REACT_APP_DISTRO_URL;
export const WEB_URL = process.env.REACT_APP_WEB_URL;
export const IPFS_URL = process.env.REACT_APP_IPFS_URL;
export const POLYGONSCAN_URL = process.env.REACT_APP_POLYGONSCAN_URL;
export const OPENSEA_URL = process.env.REACT_APP_OPENSEA_URL;

export const PINATA_JWT = process.env.REACT_APP_PINATA_JWT;

export * from "./helpers";
