import axios from "axios";
import React, { useState, CSSProperties, useEffect, useRef } from "react";
import loadingIMG from "../assets/images/loading.svg";
import { BASE_URL, DISTRO_URL,PINATA_JWT, getImage } from "../utils";
import Select, { SelectInstance } from "react-select";
import Alert from "./Alert";
import DatePicker from "react-date-picker";
import CurrencyInput from "react-currency-input-field";
import moment from "moment";

import icoCalendar from "../assets/images/icoCalendar.svg";
import icoClose from "../assets/images/close-circle.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { sha256 } from "js-sha256";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store";

export default function MintTrack({
  hideModal,
  userno,
  useremail,
  usertoken,
}: any) {
  const selectRef = useRef<SelectInstance<any> | null>(null);

  const [releases, setReleases] = useState([]);
  const [loadingReleases, setLoadingReleases] = useState(false);

  const [genres, setGenres] = useState([]);

  const [selected, setSelected] = useState(false);

  const [coverUrl, setCoverUrl] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [mintName, setMintName] = useState("");
  const [mintReleaseDate, setMintReleaseDate] = useState(new Date());
  const [mintTokens, setMintTokens] = useState("1");
  const [mintPrice, setMintPrice] = useState("1");
  const [mintGenre1, setMintGenre1] = useState("");
  const [mintGenre2, setMintGenre2] = useState("");

  const [description, setDescription] = useState("");

  const [creatingMint, setCreatingMint] = useState(false);

  const { user } = useSelector((state: ApplicationState) => state.userReducer);

  const [loadingImg, setLoadingImg] = useState(true);

  useEffect(() => {
    loadGenres();
  }, []);

  const loadGenres = async () => {
    let releaseData = `userno=${userno}&useremail=${useremail}&token=${usertoken}&cache=${Date.now()}`;
    // let releaseData = `userno=96&useremail=iandavidhaug@icloud.com&token=116bc87fe4f68e4a575b03b8cf0af05a22a507b867838958087a10915cc7515a&cache=${Date.now()}`;

    try {
      let res = await axios({
        url: `${DISTRO_URL}/getGenres.php?${releaseData}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        if (Array.isArray(res.data.data)) {
          setGenres(res.data.data);
          loadReleases();
        }
      } else {
      }
    } catch (err) {}
  };

  const loadReleases = async () => {
    setLoadingReleases(true);
    let releaseData = `userno=${userno}&useremail=${useremail}&token=${usertoken}&cache=${Date.now()}`;
    // let releaseData = `userno=96&useremail=iandavidhaug@icloud.com&token=116bc87fe4f68e4a575b03b8cf0af05a22a507b867838958087a10915cc7515a&cache=${Date.now()}`;

    try {
      let res = await axios({
        url: `${DISTRO_URL}/getReleases.php?${releaseData}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        if (Array.isArray(res.data.data)) {
          console.log("Stat");
          let data: any = [];
          await Promise.all(
            res.data.data.map(async (item: any) => {
              let release = await loadReleasesRelease(item);
              let releases: any[] = [];
              release.forEach((item2: any, index: number) => {
                releases.push({
                  label: item2[0],
                  value: item2[6],
                  albumData: item,
                  trackData: item2,
                });
              });
              data.push({
                label: item[1],
                options: releases,
              });
            })
          );

          setReleases(
            data.sort((a: any, b: any) => a.label.localeCompare(b.label))
          );
          setLoadingReleases(false);
        }
      } else {
      }
    } catch (err) {}
  };

  const loadReleasesRelease = async (release: any) => {
    let releaseData = `userno=${userno}&useremail=${useremail}&token=${usertoken}&releaseID=${
      release[0]
    }&cache=${Date.now()}`;

    // let releaseData = `userno=96&useremail=iandavidhaug@icloud.com&token=116bc87fe4f68e4a575b03b8cf0af05a22a507b867838958087a10915cc7515a&releaseID=${
    //   release[0]
    // }&cache=${Date.now()}`;

    try {
      let res = await axios({
        url: `${DISTRO_URL}/getTrackMetadata.php?${releaseData}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        if (Array.isArray(res.data.data)) {
          // console.log(JSON.stringify(res.data.data));
          return res.data.data;
        }
      } else {
      }
    } catch (err) {}
  };

  const handleSelectRelease = (data: any) => {
    setLoadingImg(true);
    setSelected(false);
    if (
      (data.albumData[3] !== "" && data.albumData[4] !== "") ||
      (data.trackData[6] !== "" && data.albumData[3] !== "")
    ) {
      setCoverUrl(
        `https://music-vault.s3.ap-southeast-2.amazonaws.com/artworks/${data.albumData[3]}/${data.albumData[4]}`
      );
      setMediaUrl(
        `https://music-vault.s3.ap-southeast-2.amazonaws.com/audios/${data.albumData[3]}/${data.trackData[6]}`
      );
      setMintGenre1(getGenre(data.albumData[7]));
      setMintGenre2(getGenre(data.albumData[8]));

      // let date = moment(data.albumData[6], "DD/MM/YYYY");
      let date = moment(data.albumData[6], "YYYY-MM-DD");
      setMintReleaseDate(date.toDate());

      setMintName(data.trackData[0]);
      setDescription("");
    } else {
      setCoverUrl("empty");
      setMediaUrl("empty");
      setDescription("");
    }
  };

  const removeMint = () => {
    selectRef.current?.clearValue();
    setSelected(false);
    setCoverUrl("");
    setMediaUrl("");
    setMintReleaseDate(new Date());
    setMintTokens("");
    setMintPrice("");
    setMintGenre1("");
    setMintGenre2("");
    setDescription("");
  };

  const getGenre = (genre: string) => {
    let value = "";
    genres.forEach((item: any) => {
      if (item[0] === genre) {
        value = item[1];
      }
    });

    return value;
  };

  const addMint = async () => {
    let genres = [];
    if (mintGenre1 !== "") {
      genres.push(mintGenre1);
    }
    if (mintGenre2 !== "") {
      genres.push(mintGenre2);
    }

    setCreatingMint(true);

    let res = await axios({
      url: `${BASE_URL}/api/v1/pressing-machine/mint/normal`,
      method: "POST",
      data: {
        name: mintName,
        image: "",
        image_original: coverUrl,
        description: description,
        tokens: mintTokens,
        suggested_price: mintPrice,
        animation_url: "",
        animation_url_original: mediaUrl,
        release_date: mintReleaseDate,
        metadata: "",
        genres: JSON.stringify(genres),
        user_id: user.id,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status === 200) {
      setCreatingMint(false);
      hideModal();
    } else {
      // alert("Error");
      setCreatingMint(false);
      toast.error(
        "There has been an issue creating your item. Please try again later or contact us."
      );
    }

    setCreatingMint(false);
  };

  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <div className={"modal-window show"} id="open-modal">
      <div className="modal-content">
        <div className="modal-heading">
          <h1>Press New NFT</h1>
          <button onClick={() => hideModal()} className="modal-close"></button>
        </div>

        <Alert
          type="purple"
          title="First time minting?"
          desc={
            "If this is your first NFT mint and you're not sure what the input data should look like, check out this reference collection on OpenSea"
          }
        />

        <div>
          <div className="form">
            <div className="form-group">
              <label>
                <div>Search your track</div>

                <div className="help-tooltip">
                  <div className="tooltip-box">
                    <div className="tooltip-box-inner">
                      <h3>Find your track effortlessly</h3>
                      <p>
                        Use the search feature to quickly locate your music
                        track from the list of previously uploaded songs. Simply
                        start typing the name or relevant keywords of your
                        track, and the select box will dynamically filter the
                        options, making it easy to find the specific track you
                        want to mint as an NFT. This saves you time and ensures
                        you select the correct track for the minting process.
                      </p>
                    </div>
                  </div>
                </div>
              </label>
              <Select
                options={releases}
                formatGroupLabel={formatGroupLabel}
                ref={selectRef}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "#121619!important"
                      : "#121619!important",
                    borderWidth: 2,
                    height: 47,
                    fontSize: 14,
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#f2ebfc",
                    primary: "#121619",
                  },
                })}
                classNamePrefix="react-select"
                className="react-select-container"
                onChange={(data: any) => {
                  handleSelectRelease(data);
                }}
                isLoading={loadingReleases}
                isDisabled={selected}
              />
            </div>
            {selected && (
              <div className="mint-start-again">
                <button onClick={() => removeMint()}>
                  Delete MP3 and start again
                </button>
              </div>
            )}

            {coverUrl !== "" &&
              mediaUrl !== "" &&
              coverUrl !== "empty" &&
              mediaUrl !== "empty" &&
              selected && (
                <div>
                  <div className="form-group">
                    <label>
                      <div>Album cover</div>
                    </label>
                    <div className="cover-box">
                      <div className="square-box">
                        {loadingImg && (
                          <img
                            src={loadingIMG}
                            alt="Loading"
                            width={30}
                            height={30}
                            className="loading-animation"
                            style={{
                              filter: "invert(1)",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translateX(-50%,0)",
                              marginLeft: -15,
                              marginTop: -15,
                            }}
                          />
                        )}
                        <div className="square-content">
                          <img
                            src={getImage(coverUrl, "th")}
                            alt="Cover"
                            width="100%"
                            onLoad={() => setLoadingImg(true)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {(mintGenre1 !== "" || mintGenre2 !== "") && (
                    <div className="form-group">
                      <label>
                        <div>Track Genre</div>
                      </label>
                      <div className="labels">
                        {mintGenre1 !== "" && <span>{mintGenre1}</span>}
                        {mintGenre2 !== "" && <span>{mintGenre2}</span>}
                      </div>
                    </div>
                  )}

                  <div className="form-group" style={{ marginTop: 23 }}>
                    <label>
                      <div>Description</div>
                    </label>
                    <textarea
                      className="form-control form-control"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label>
                      <div>Track Release Date</div>
                    </label>
                    <DatePicker
                      onChange={setMintReleaseDate}
                      value={mintReleaseDate}
                      className="form-control"
                      // calendarIcon={
                      //   <img src={icoCalendar} width="22" alt="Open" />
                      // }
                      // clearIcon={<img src={icoClose} width="22" alt="Clear" />}
                      calendarIcon={null}
                      clearIcon={null}
                      disabled
                    />
                  </div>

                  <div className="form-group">
                    <label>
                      <div>Mint token count</div>

                      <div className="help-tooltip">
                        <div className="tooltip-box">
                          <div className="tooltip-box-inner">
                            <h3>Set the availability of your NFTs</h3>
                            <p>
                              The "mint token count" allows you to determine the
                              quantity of NFTs that will be available for
                              purchase or minting by your fans. It represents
                              the total number of unique tokens that will be
                              created for your selected track. Consider the
                              level of exclusivity you want to offer to your
                              fans and choose a suitable quantity accordingly.
                              Whether you prefer a limited supply or a larger
                              number of NFTs, this step allows you to control
                              the availability and scarcity of your digital
                              collectibles, adding value and excitement for your
                              fans.
                            </p>
                          </div>
                        </div>
                      </div>
                    </label>
                    <div className="number-box">
                      <button
                        className="minus"
                        onClick={() => {
                          setMintTokens(String(Number(mintTokens) - 1));
                        }}
                        disabled={Number(mintTokens) === 1}
                      ></button>

                      <input
                        type="number"
                        className="form-control form-control-number"
                        value={mintTokens}
                        max={10000}
                        min={1}
                        step={1}
                        onChange={(e) => {
                          if (Number(e.target.value) > 10000) {
                            setMintTokens("10000");
                          } else if (Number(e.target.value) < 1) {
                            setMintTokens("1");
                          } else {
                            setMintTokens(
                              String(Math.trunc(Number(e.target.value)))
                            );
                          }
                        }}
                      />
                      <button
                        className="plus"
                        onClick={() => {
                          setMintTokens(String(Number(mintTokens) + 1));
                        }}
                      ></button>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      <div>Mint price</div>

                      <div className="help-tooltip">
                        <div className="tooltip-box">
                          <div className="tooltip-box-inner">
                            <h3>Determine the value of your NFTs</h3>
                            <p>
                              The "mint price" allows you to set the price for
                              each token of your NFT in USD. Consider the
                              exclusivity, availability, and perceived value of
                              your NFTs when determining the price. Think about
                              factors such as the uniqueness of the track, its
                              popularity, and the demand from your fans. Finding
                              the right balance ensures that your NFTs are
                              priced attractively, reflecting their worth while
                              also considering the affordability for your fans.
                              Remember, the price you set can influence the
                              perceived value of your NFTs and contribute to
                              their success in the marketplace.
                            </p>
                          </div>
                        </div>
                      </div>
                    </label>
                    <div className="number-box">
                      <button
                        className="minus"
                        onClick={() => {
                          if (Number(mintPrice) - 1 < 1) {
                            setMintPrice("1");
                          } else {
                            setMintPrice(String(Number(mintPrice) - 1));
                          }
                        }}
                        disabled={Number(mintPrice) === 0}
                      ></button>

                      <CurrencyInput
                        className="form-control form-control-number"
                        placeholder="Please enter a number"
                        defaultValue={1}
                        decimalsLimit={2}
                        min={0}
                        value={mintPrice}
                        prefix="$"
                        decimalSeparator="."
                        onValueChange={(value, name) => {
                          if (typeof value === "undefined") {
                            setMintPrice(String(0));
                          } else {
                            setMintPrice(String(value));
                          }
                          console.log(value);
                        }}
                      />

                      <button
                        className="plus"
                        onClick={() => {
                          console.log(Number(mintPrice));
                          setMintPrice(String(Number(mintPrice) + 1));
                        }}
                      ></button>
                    </div>
                  </div>
                </div>
              )}

            {(coverUrl === "empty" || mediaUrl === "empty") && (
              <Alert type={"red"} title="Error" desc={"Missing media data"} />
            )}

            {/* {creatingMint && (
              <img
                src={loadingIMG}
                alt="Loading"
                width={20}
                height={20}
                className="loading-animation"
              />
            )}
            {!creatingMint ? "Submit" : "Submitted"} */}

            {selected && (
              <>
                <Alert
                  type="red"
                  title="Important notice!"
                  desc={
                    "The minting process is irreversible. Please check your data carefully before submitting."
                  }
                />

                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => addMint()}
                  disabled={mintGenre1 === ""}
                >
                  {creatingMint && (
                    <img
                      src={loadingIMG}
                      alt="Loading"
                      width={20}
                      height={20}
                      className="loading-animation"
                    />
                  )}
                  {!creatingMint ? "Submit" : "Submitted"}
                </button>
              </>
            )}

            {!selected && (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => setSelected(true)}
                disabled={
                  coverUrl === "empty" ||
                  mediaUrl === "empty" ||
                  coverUrl === "" ||
                  mediaUrl === ""
                }
              >
                Continue
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles: CSSProperties = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};
