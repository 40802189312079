import axios from "axios";
import { sha256 } from "js-sha256";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import { BASE_URL, DISTRO_URL, getImage } from "../utils";
import { useNavigate } from "react-router-dom";
import loadingIMG from "../assets/images/loading_black.svg";
import addAccount from "../assets/images/icoAddPhoto.svg";

import { ApplicationState, onLoading, loadUser, loadAccounts } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Alert from "../components/Alert";

import icoDashboard from "../assets/images/icoDashboard.svg";
import icoLogout from "../assets/images/icoLogout.svg";

export default function AccountsPage({ userno, useremail, usertoken }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState("");

  const { loading } = useSelector(
    (state: ApplicationState) => state.loadingReducer
  );

  const { accounts } = useSelector(
    (state: ApplicationState) => state.accountsReducer
  );

  const loadAccountsData = async () => {
    await Promise.all([
      dispatch(onLoading(true)),
      dispatch(loadAccounts(userno,useremail)),
    ]);

    return dispatch(onLoading(false));
  };

  const loadUserDataDistro = async () => {
    try {
      let res = await axios({
        url: `${DISTRO_URL}/getUserDetails.php?userno=${userno}&useremail=${useremail}&token=${usertoken}&cache=${Date.now()}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        if (typeof res.data.Firstname !== "undefined") {
          setName(res.data.Firstname);
        }
      } else {
      }
    } catch (err) {}
  };

  useEffect(() => {
    //loadUserDataDistro();
    loadAccountsData();
  }, []);

  const loadUserData = async (id: string) => {
    await Promise.all([dispatch(onLoading(true)), dispatch(loadUser(id))]);

    navigate("/home");
    return dispatch(onLoading(false));
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12"></div>
          </div>
        </div>
      </header>
      <h1 className="main-heading">
        {accounts.length === 0 ? "Hello" : "Choose artist"}
      </h1>

      <main className="container">
        <div className="row">
          <div className="col-sm-12">
            {loading && (
              <div className="content content-artist">
                <img
                  src={loadingIMG}
                  alt="Loading"
                  width={20}
                  height={20}
                  className="loading-animation"
                  style={{ marginLeft: 8 }}
                />
              </div>
            )}
            {!loading && (
              <div className="content content-artist">
                {accounts.length === 0 ? (
                  <>
                    <div className="accounts-empty">
                      <img
                        src={addAccount}
                        alt="Create new artist"
                        className="page-ico"
                      />
                      <button
                        onClick={() => navigate("/init")}
                        className="btn-black btn-small"
                      >
                        Create new artist
                      </button>

                      <p>
                        You can create up to three different artists under your
                        profile.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="my-accounts">
                      {accounts.map((item: any, index: number) => (
                        <div
                          className="account-box"
                          key={index}
                          style={{
                            borderColor:
                              index === 2 ? "transparent" : "#121619",
                          }}
                        >
                          <div className="account-cover">
                            <img src={getImage(item.image, "th")} alt="Cover" />
                          </div>
                          <div className="account-content">
                            <div className="account-name">{item.name}</div>

                            <button
                              onClick={() => loadUserData(item.id)}
                              className="btn-black btn-small"
                            >
                              Select artist
                            </button>
                          </div>
                        </div>
                      ))}
                      {accounts.length < 3 && !loading && (
                        <div
                          className="account-box"
                          style={{ borderBottom: 0 }}
                        >
                          <div className="account-cover">
                            <img
                              src={addAccount}
                              alt="Create new artist"
                              className="page-ico"
                            />
                          </div>
                          <div className="account-content">
                            <button
                              onClick={() => navigate("/init")}
                              className="btn-black btn-small"
                              style={{ marginTop: 27 }}
                            >
                              Create new artist
                            </button>
                            <p className="desc">
                              You can create up to three different artists under
                              your profile.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}
