import React from "react";

export default function Action({}: any) {
  React.useEffect(() => {
    iframeHeightNotify();

    setInterval(() => {
      iframeHeightNotify();
    }, 300);
  }, []);

  const iframeHeightNotify = () => {
    var body = document.body;

    var scrollHeight = Math.max(body.scrollHeight, body.offsetHeight);

    var scrollHeightModal = document.getElementById("open-modal")?.scrollHeight;

    if (scrollHeightModal) {
      if (scrollHeightModal > scrollHeight) {
        scrollHeight = scrollHeightModal;
      }
    }
    // if (scrollHeightModal === 0) {
    //   scrollHeight = Math.max(
    //     document.body.scrollHeight,
    //     document.documentElement.scrollHeight,
    //     document.body.offsetHeight,
    //     document.documentElement.offsetHeight,
    //     document.body.clientHeight,
    //     document.documentElement.clientHeight
    //   );
    // }

    var msg = {
      height: scrollHeight,
    };
    window.parent.postMessage(msg, "*");
  };

  return <div></div>;
}
