import React from "react";

import { loadUser, ApplicationState, onLoading } from "../store";
import { useSelector, useDispatch } from "react-redux";

export default function Action({ type, text, onClick }: any) {
  return (
    <button className={"action-button " + type} onClick={() => onClick()}>
      {text}
    </button>
  );
}
