import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import Alert from "../components/Alert";
import axios from "axios";
import DatePicker from "react-date-picker";
import CurrencyInput from "react-currency-input-field";

import placeholderIMG from "../assets/images/placeholder.svg";
import loadingIMG from "../assets/images/loading.svg";
import icoCalendar from "../assets/images/icoCalendar.svg";
import icoClose from "../assets/images/close-circle.svg";

import icoDashboard from "../assets/images/icoDashboard.svg";
import icoLogout from "../assets/images/icoLogout.svg";

import {
  BASE_URL,
  DISTRO_URL,
  getImage,
  IPFS_URL,
  OPENSEA_URL,
  POLYGONSCAN_URL,
  WEB_URL,
} from "../utils";

import { ApplicationState, onLoading, loadUser } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { sha256 } from "js-sha256";
import { toast } from "react-toastify";

//@ts-ignore
import { NFTStorage } from "nft.storage/dist/bundle.esm.min.js";
import { NFT_STORAGE_API_KEY } from "../utils/nftstorage";
import MintTrack from "../components/MintTrack";

export default function MintsPage({ userno, useremail, usertoken }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { open } = useParams();

  const [showMintModal, setShowMintModal] = useState(false);
  const [showMintModal2, setShowMintModal2] = useState(false);

  const [covers, setCovers] = useState([]);
  const [loadingCover, setLoadingCover] = useState(false);
  const [loadingMP3, setLoadingMP3] = useState(false);
  const [loading, setLoading] = useState(false);

  const [creatingMint, setCreatingMint] = useState(false);
  const [mp3, setMp3] = useState([]);

  const [mintName, setMintName] = useState("");
  const [mintDescription, setMintDescription] = useState("");
  const [mintCoverIpfs, setMintCoverIpfs] = useState("");
  const [mintCoverImage, setMintCoverImage] = useState("");
  const [mintTokens, setMintTokens] = useState("1");
  const [mintPrice, setMintPrice] = useState("1");
  const [mintReleaseDate, setMintReleaseDate] = useState(new Date());
  const [mintGenres, setMintGenres] = useState("");
  const [mintMp3, setMintMp3] = useState("");

  const [mp3Filename, setMp3Filename] = useState("");

  const [mp3UploadError, setMp3UploadError] = useState(false);
  const [imageUploadError, setImageUploadError] = useState(false);

  const { user } = useSelector((state: ApplicationState) => state.userReducer);

  useEffect(() => {
    if (typeof user.email === "undefined") {
      navigate("/login");
    }
  }, [user]);

  useEffect(() => {
    let foundGenerating = false;
    user.mints.forEach((item: any) => {
      if (item.status === "0") {
        foundGenerating = true;
      }
    });

    if (foundGenerating) {
      const timeoutID = window.setTimeout(() => {
        loadUserDataBackground();
      }, 10000);

      return () => {
        clearTimeout(timeoutID);
      };
    }
  }, [user]);

  useEffect(() => {
    if (open === "1") {
      setShowMintModal2(true);
    }
    loadUserData();
  }, [open]);

  const loadUserData = async () => {
    setLoading(true);
    await Promise.all([dispatch(onLoading(true)), dispatch(loadUser(user.id))]);

    setLoading(false);
    return dispatch(onLoading(false));
  };

  const loadUserDataBackground = async () => {
    await dispatch(loadUser(user.id));
  };

  //@ts-ignore
  const onChangeCover = (imageList, addUpdateIndex) => {
    setCovers(imageList);

    if (imageList.length > 0) {
      setImageUploadError(false);
      uploadCover(
        imageList[0].data_url,
        imageList[0].file.name,
        imageList[0].file
      );
    }
  };

  const uploadCover = async (data: string, name: string, file: File) => {
    setLoadingCover(true);

    try {
      const client = new NFTStorage({ token: NFT_STORAGE_API_KEY });

      const ipfsHashImage = await client.storeBlob(file);

      if (ipfsHashImage) {
        setMintCoverIpfs(ipfsHashImage);
        setMintCoverImage(ipfsHashImage);
        setLoadingCover(false);
      } else {
        setLoadingCover(false);
      }
    } catch (error) {
      setLoadingCover(false);
    }
  };

  //@ts-ignore
  const onChangeMp3 = (imageList, addUpdateIndex) => {
    setMp3(imageList);

    if (imageList.length > 0) {
      setMp3UploadError(false);
      uploadMp3(
        imageList[0].data_url,
        imageList[0].file.name,
        imageList[0].file
      );

      setMp3Filename(imageList[0].file.name);
    }
  };

  const uploadMp3 = async (data: string, name: string, file: File) => {
    setLoadingMP3(true);

    try {
      const client = new NFTStorage({ token: NFT_STORAGE_API_KEY });

      const ipfsHashMP3 = await client.storeBlob(file);

      if (ipfsHashMP3) {
        setMintMp3(ipfsHashMP3);
        setLoadingMP3(false);
      } else {
        setLoadingMP3(false);
      }
    } catch (error) {
      setLoadingMP3(false);
    }
  };

  const addMint = async () => {
    setCreatingMint(true);
    console.log(user);
    try {
      const client = new NFTStorage({ token: NFT_STORAGE_API_KEY });

      var metadata = {
        description: mintDescription,
        external_url: BASE_URL + "/metadata/" + user.permalink,
        image: "ipfs://" + mintCoverImage,
        animation_url: "ipfs://" + mintMp3,
        name: mintName,
        attributes: [
          {
            display_type: "date",
            trait_type: "Release date",
            value: mintReleaseDate.getTime(),
          },
          {
            trait_type: "Genre",
            value: mintGenres,
          },
        ],
      };
      var jsonse = JSON.stringify(metadata);
      var blob = new Blob([jsonse], { type: "application/json" });

      const ipfsHash = await client.storeBlob(blob);

      if (ipfsHash) {
        let res = await axios({
          url: `${BASE_URL}add_mint_custom.php?cache=${Date.now()}`,
          method: "POST",
          data: {
            name: mintName,
            image: mintCoverImage,
            description: mintDescription,
            tokens: mintTokens,
            suggested_price: mintPrice,
            animation_url: mintMp3,
            release_date: mintReleaseDate,
            metadata: ipfsHash,
            genres: JSON.stringify([mintGenres]),
            user_id: user.id,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.status === 200) {
          setMintName("");
          setMintCoverImage("");
          setMintDescription("");
          setMintTokens("1");
          setMintPrice("1");
          setMintMp3("");
          setMintReleaseDate(new Date());
          setMintGenres("");
          setShowMintModal(false);
          loadUserData();
          setCovers([]);
          setMp3Filename("");
          setMintCoverIpfs("");
          setMp3([]);
        } else {
          // alert("Error");
          toast.error(
            "There has been an issue creating your item. Please try again later or contact us."
          );
        }
      } else {
        setCreatingMint(false);
        // console.log(err);

        toast.error(
          "There has been an issue creating your item. Please try again later or contact us."
        );
      }

      setCreatingMint(false);
    } catch (err) {
      setCreatingMint(false);
      // console.log(err);

      toast.error(
        "There has been an issue creating your item. Please try again later or contact us."
      );
    }
  };

  const getTokenCount = (data: string) => {
    let value = JSON.parse(data)["logs"][0]["data"].slice(2);
    let size = value.length / 2;

    let half1 = value.substring(0, value.length / 2);
    let half2 = value.substring(value.length / 2);

    return parseInt(half2, 16);
  };

  const getMintToken = (data: string) => {
    let value = JSON.parse(data)["logs"][0]["data"].slice(2);
    let size = value.length / 2;

    let half1 = value.substring(0, value.length / 2);
    let half2 = value.substring(value.length / 2);

    return parseInt(half1, 16);
  };

  const Loader = () => {
    return (
      <div className="uploading-bar">
        <div className="uploading-bar-inner">
          <div className="uploading-bar-progress"></div>
        </div>
      </div>
    );
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="header-buttons">
                <button onClick={() => navigate("/home")} className="btn-left">
                  <img src={icoDashboard} alt="My Dashboard" />
                  <span>My Dashboard</span>
                </button>
                <div className="divider" />

                <button onClick={() => navigate("/")} className="btn-right">
                  <span>Log out</span>
                  <img src={icoLogout} alt="Log out" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <h1 className="main-heading">{user.name}</h1>

      <div className="profile-photo-box">
        <img
          className="profile-photo"
          src={getImage(user.image, "th")}
          alt={user.name}
        />
      </div>

      <main className="container main-big ">
        <div className="row">
          <div className="col-sm-12">
            <div className="content content-main not-flex">
              <div className="center">
                <button
                  className="btn-black"
                  onClick={() => setShowMintModal2(true)}
                >
                  Press a new NFT
                </button>
              </div>

              <div className="my-mints">
                <h3>My Mints</h3>

                {!loading && user.mints.length === 0 && (
                  <Alert
                    type="purple"
                    title="You don’t have any NFT so far, mint your first one!"
                    desc={""}
                  />
                )}
                {loading && (
                  <div style={{ minHeight: 600 }} className="placeholder-box">
                    <div>
                      <img
                        src={placeholderIMG}
                        alt="Loading"
                        width={416}
                        height={144}
                      />
                    </div>
                    <div style={{ marginTop: 44 }}>
                      <img
                        src={placeholderIMG}
                        alt="Loading"
                        width={416}
                        height={144}
                      />
                    </div>
                    <div style={{ marginTop: 44 }}>
                      <img
                        src={placeholderIMG}
                        alt="Loading"
                        width={416}
                        height={144}
                      />
                    </div>
                  </div>
                )}

                {!loading && (
                  <>
                    {user.mints.map((item: any) => (
                      <div className="mint-box" key={JSON.stringify(item)}>
                        {item.status === "0" && (
                          <>
                            {(item.approved === 0 ||
                              item.approved === 1) && (
                              <div className="mint-cover mint-pending">
                                <img
                                  src={getImage(
                                    item.type === "custom"
                                      ? item.image
                                      : item.image_original,
                                    "th"
                                  )}
                                  alt="Cover"
                                />
                              </div>
                            )}

                            {item.approved === 2 && (
                              <div className="mint-cover mint-fail">
                                <img
                                  src={getImage(
                                    item.type === "custom"
                                      ? item.image
                                      : item.image_original,
                                    "th"
                                  )}
                                  alt="Cover"
                                />
                              </div>
                            )}
                          </>
                        )}
                        {item.status === 1 && (
                          <div className="mint-cover mint-success">
                            <img
                              src={getImage(
                                item.type === "custom"
                                  ? item.image
                                  : item.image_original,
                                "th"
                              )}
                              alt="Cover"
                            />
                          </div>
                        )}
                        {item.status === 2 && (
                          <div className="mint-cover mint-fail">
                            <img
                              src={getImage(
                                item.type === "custom"
                                  ? item.image
                                  : item.image_original,
                                "th"
                              )}
                              alt="Cover"
                            />
                          </div>
                        )}

                        <div className="mint-content">
                          <div className="mint-name">{item.name}</div>
                          {item.status === 0 && (
                            <>
                              {item.approved === 1 && (
                                <div className="mint-pending-box">
                                  Mint in progress
                                </div>
                              )}

                              {item.approved === 0 && (
                                <div className="mint-pending-box">
                                  Waiting for approval
                                </div>
                              )}

                              {item.approved === 2 && (
                                <div className="mint-fail-box">Rejected</div>
                              )}
                            </>
                          )}
                          {item.status === 1 && (
                            <div className="mint-success-box">
                              Minting successful
                            </div>
                          )}
                          {item.status === 2 && (
                            <div className="mint-fail-box">
                              Minting has failed
                            </div>
                          )}
                          {item.metadata !== null && (
                            <p>
                              <strong>Metadata:</strong>{" "}
                              <a
                                href={IPFS_URL + "/" + item.metadata}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Open metadata
                              </a>
                            </p>
                          )}

                          {item.transaction !== null && (
                            <p>
                              <strong>Transaction ID:</strong>{" "}
                              <a
                                href={
                                  POLYGONSCAN_URL + "/tx/" + item.transaction
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                Show on polygonscan.com
                              </a>
                            </p>
                          )}

                          <p>
                            <strong>Number of tokens:</strong>{" "}
                            {Number(item.tokens) - 1}
                          </p>
                          <p>
                            <strong>Price:</strong> {item.suggested_price} USDC
                          </p>
                          {item.status === 1 && (
                            <div className="mint-links">
                              <a
                                href={
                                  OPENSEA_URL +
                                    "/" +
                                  user.contract +
                                  "/" +
                                  getMintToken(item.transaction_data)
                                }
                                className="btn-opensea"
                                target="_blank"
                                rel="noreferrer"
                              >
                                OpenSea
                              </a>
                              <a
                                href={
                                  WEB_URL +
                                  "/artist/" +
                                  user.permalink +
                                  "/" +
                                  item.id
                                }
                                className="btn-gram180"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Gram180
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      {showMintModal && (
        <div className={"modal-window show"} id="open-modal">
          <div className="modal-content">
            <div className="modal-heading">
              <h1>Press New NFT</h1>
              <button
                onClick={() => setShowMintModal2(false)}
                className="modal-close"
              ></button>
            </div>

            <Alert
              type="purple"
              title="First time minting?"
              desc={
                "If this is your first NFT mint and you're not sure what the input data should look like, check out this reference collection on OpenSea"
              }
            />

            <div>
              <div className="form">
                <div className="form-group">
                  <label>Item name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Item name"
                    onChange={(e) => setMintName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Item description</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Item description"
                    onChange={(e) => setMintDescription(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Album cover</label>
                  <ImageUploading
                    multiple
                    value={covers}
                    onChange={onChangeCover}
                    maxNumber={1}
                    dataURLKey="data_url"
                    onError={() => setImageUploadError(true)}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper-cover">
                        {imageList.length === 0 && !imageUploadError && (
                          <button
                            style={
                              isDragging
                                ? { color: "#7b3fe4", borderColor: "#7b3fe4" }
                                : undefined
                            }
                            onClick={onImageUpload}
                            {...dragProps}
                            className={"dragbox-album"}
                          >
                            Click or drag to add cover image
                            <small>
                              Info about recommended sizes and formats.
                            </small>
                          </button>
                        )}

                        {imageList.length === 0 && imageUploadError && (
                          <button
                            style={
                              isDragging
                                ? { color: "#7b3fe4", borderColor: "#7b3fe4" }
                                : undefined
                            }
                            onClick={onImageUpload}
                            {...dragProps}
                            className={"dragbox-album"}
                          >
                            Click or drag to add cover image
                            <small>
                              Info about recommended sizes and formats.
                            </small>
                            <div className="uploadError">
                              Wrong file format. Upload only image files!
                            </div>
                          </button>
                        )}

                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            {loadingCover && <Loader />}

                            <div
                              className="image-uploaded"
                              style={{
                                backgroundImage: `url(${image["data_url"]})`,
                                backgroundSize: "cover",
                                width: "100%",
                                height: "100%",
                              }}
                            ></div>
                            <div className="image-item__btn-wrapper">
                              <button
                                onClick={() => onImageRemove(index)}
                                className="remove-ico"
                              >
                                REPLACE IMAGE
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className="form-group">
                  <label>Mint token count</label>
                  <div className="number-box">
                    <button
                      className="minus"
                      onClick={() => {
                        setMintTokens(String(Number(mintTokens) - 1));
                      }}
                      disabled={Number(mintTokens) === 1}
                    ></button>

                    <input
                      type="number"
                      className="form-control form-control-number"
                      value={mintTokens}
                      max={10000}
                      min={1}
                      step={1}
                      onChange={(e) => {
                        if (Number(e.target.value) > 10000) {
                          setMintTokens("10000");
                        } else if (Number(e.target.value) < 1) {
                          setMintTokens("1");
                        } else {
                          setMintTokens(
                            String(Math.trunc(Number(e.target.value)))
                          );
                        }
                      }}
                    />
                    <button
                      className="plus"
                      onClick={() => {
                        setMintTokens(String(Number(mintTokens) + 1));
                      }}
                    ></button>
                  </div>
                </div>
                <div className="form-group">
                  <label>Album MP3</label>
                  <ImageUploading
                    multiple={false}
                    value={mp3}
                    onChange={onChangeMp3}
                    maxNumber={1}
                    dataURLKey="data_url"
                    acceptType={["mp3"]}
                    allowNonImageType
                    onError={() => setMp3UploadError(true)}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper-mp3">
                        {imageList.length === 0 && !mp3UploadError && (
                          <button
                            style={
                              isDragging
                                ? { color: "#7b3fe4", borderColor: "#7b3fe4" }
                                : undefined
                            }
                            onClick={onImageUpload}
                            {...dragProps}
                            className={"dragbox-mp3"}
                          >
                            Click or drag to add MP3 file
                          </button>
                        )}

                        {imageList.length === 0 && mp3UploadError && (
                          <button
                            style={
                              isDragging
                                ? { color: "#7b3fe4", borderColor: "#7b3fe4" }
                                : undefined
                            }
                            onClick={onImageUpload}
                            {...dragProps}
                            className={"dragbox-mp3 error-box"}
                          >
                            Click or drag to add MP3 file
                            <div className="uploadError">
                              Wrong file format. Upload only mp3 files!
                            </div>
                          </button>
                        )}

                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            {loadingMP3 ? (
                              <Loader />
                            ) : (
                              <div className="filename">{mp3Filename}</div>
                            )}

                            <div className="image-item__btn-wrapper-mp3">
                              <button
                                onClick={() => {
                                  onImageRemove(index);
                                  setMp3Filename("");
                                }}
                                className="remove-ico"
                              >
                                REPLACE MP3
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className="form-group">
                  <label>Mint price</label>
                  <div className="number-box">
                    <button
                      className="minus"
                      onClick={() => {
                        if (Number(mintPrice) - 1 < 0) {
                          setMintPrice("0");
                        } else {
                          setMintPrice(String(Number(mintPrice) - 1));
                        }
                      }}
                      disabled={Number(mintPrice) === 0}
                    ></button>

                    <CurrencyInput
                      className="form-control form-control-number"
                      placeholder="Please enter a number"
                      defaultValue={1}
                      decimalsLimit={2}
                      fixedDecimalLength={2}
                      value={mintPrice}
                      prefix="$"
                      decimalSeparator="."
                      onValueChange={(value, name) => {
                        setMintPrice(String(value));
                        console.log(value);
                      }}
                    />

                    <button
                      className="plus"
                      onClick={() => {
                        console.log(Number(mintPrice));
                        setMintPrice(String(Number(mintPrice) + 1));
                      }}
                    ></button>
                  </div>
                </div>
                <div className="form-group">
                  <label>Mint Release Date</label>
                  <DatePicker
                    onChange={setMintReleaseDate}
                    value={mintReleaseDate}
                    className="form-control"
                    calendarIcon={
                      <img src={icoCalendar} width="22" alt="Open" />
                    }
                    clearIcon={<img src={icoClose} width="22" alt="Clear" />}
                  />
                </div>
                <div className="form-group">
                  <label>Mint genre</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mint genre"
                    value={mintGenres}
                    onChange={(e) => {
                      setMintGenres(e.target.value);
                    }}
                  />
                </div>
                <Alert
                  type="red"
                  title="Important notice!"
                  desc={
                    "The minting process is irreversible. Please check your data carefully before submitting."
                  }
                />

                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    addMint();
                    console.log("mintName", mintName);
                    console.log("mintDescription", mintDescription);
                    console.log("mintCoverImage", mintCoverImage);
                    console.log("mintTokens", mintTokens);
                    console.log("mintMp3", mintMp3);
                    console.log("mintPrice", mintPrice);
                    console.log("mintReleaseDate", mintReleaseDate);
                    // console.log("mintGenre", mintGenre);
                  }}
                  disabled={
                    mintName === "" ||
                    mintDescription === "" ||
                    mintCoverImage === "" ||
                    mintTokens === "" ||
                    mintMp3 === "" ||
                    mintPrice === "" ||
                    String(mintReleaseDate) === "" ||
                    mintGenres === ""
                  }
                >
                  {creatingMint && (
                    <img
                      src={loadingIMG}
                      alt="Loading"
                      width={20}
                      height={20}
                      className="loading-animation"
                    />
                  )}
                  {!creatingMint ? "Submit" : "Submitted"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMintModal2 && (
        <MintTrack
          hideModal={() => {
            loadUserData();
            setShowMintModal2(false);
          }}
          userno={userno}
          useremail={useremail}
          usertoken={usertoken}
        />
      )}
      <Footer />
    </>
  );
}
