import axios from "axios";
import { Dispatch } from "react";
import { BASE_URL } from "../../utils";
export interface UserInterface {
  readonly type: "LOAD_USER";
  payload: any;
}

export type LoadUser = UserInterface;

export const loadUser = (id: string) => {
  return async (dispatch: Dispatch<UserInterface>) => {
    try {

      const response = await axios.get<any>(
        `${BASE_URL}/api/v1/pressing-machine/artist/${id}`
      );

      // const response = await axios.get<any>(
      //     `https://api.gram180.com/get_user_data.php?id=4229e8b440db170ea980fa9991169cb7f8058abc1538bedf8425ca1120426f5e_4015&cache=${Date.now()}`
      // );

      if (!response) {
      } else {
        dispatch({
          type: "LOAD_USER",
          payload: response.data,
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };
};

export const clearUser = () => {
  return async (dispatch: Dispatch<UserInterface>) => {
    dispatch({
      type: "LOAD_USER",
      payload: { mints: [] },
    });
  };
};
