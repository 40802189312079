import { BASE_URL } from ".";
import icoProfile from "../assets/images/icoProfile.svg";

export const getImage = (url: string, size: string) => {
  // size th, f
  if (url === "") {
    return icoProfile;
  } else {
    return `${BASE_URL}/api/v1/ipfs-cache/image-handler?url=${url}&size=${size}`;
  }
};

export const formatLink = (url: string) => {
  if (typeof url !== "undefined") {
    if (!url.includes("://") && !url.startsWith("/")) {
      return (url = "https://" + url);
    } else {
      return url;
    }
  } else {
    return "";
  }
};
