import { LoadUser } from "../actions/userActions";

type UserState = {
  user: any;
  error: string | undefined;
};

const initialState = {
  user: { mints: [] } as any,
  error: undefined,
};

const UserReducer = (state: UserState = initialState, action: LoadUser) => {
  switch (action.type) {
    case "LOAD_USER":
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};

export { UserReducer };
