import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import Action from "../components/Action";
import ProfileForm from "../components/ProfileForm";

import spotifyIMG from "../assets/images/spotify.svg";
import appleIMG from "../assets/images/apple.svg";
import tidalIMG from "../assets/images/tidal.svg";
import facebookIMG from "../assets/images/facebook.svg";

import icoDashboard from "../assets/images/icoDashboard.svg";
import icoLogout from "../assets/images/icoLogout.svg";

import { BASE_URL, formatLink, getImage, POLYGONSCAN_URL } from "../utils";

import { ApplicationState, onLoading, loadUser } from "../store";
import { useSelector, useDispatch } from "react-redux";

export default function ProfilePage({ userno, useremail, usertoken }: any) {
  const navigate = useNavigate();

  const { user } = useSelector((state: ApplicationState) => state.userReducer);

  useEffect(() => {
    if (typeof user.email === "undefined") {
      navigate("/login/logout");
    }
  }, [user]);

  const [showProfileModal, setShowProfileModal] = useState(false);

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="header-buttons">
                <button onClick={() => navigate("/home")} className="btn-left">
                  <img src={icoDashboard} alt="My Dashboard" />
                  <span>My Dashboard</span>
                </button>
                <div className="divider" />

                <button onClick={() => navigate("/")} className="btn-right">
                  <span>Log out</span>
                  <img src={icoLogout} alt="Log out" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <h1 className="main-heading">{user.name}</h1>

      <div className="profile-photo-box">
        <img
          className="profile-photo"
          src={getImage(user.image, "th")}
          alt={user.name}
        />
      </div>

      <main className="container main-big">
        <div className="row">
          <div className="col-sm-12">
            <div className="content content-main not-flex">
              <div className="center">
                <button
                  className="btn-black"
                  onClick={() => setShowProfileModal(true)}
                >
                  EDIT INFO
                </button>

                <div className="profile-socials">
                  {user.spotify_url !== "" && (
                    <a
                      href={formatLink(user.spotify_url)}
                      target="_blank"
                      rel="noreferrer"
                      className="social-ico"
                    >
                      <img src={spotifyIMG} alt="Spotify" />
                    </a>
                  )}

                  {/* <a
                    href="https://www.spotify.com"
                    target="_blank"
                    rel="noreferrer"
                    className="social-ico"
                  >
                    <img src={appleIMG} alt="Apple" />
                  </a>
                  <a
                    href="https://www.spotify.com"
                    target="_blank"
                    rel="noreferrer"
                    className="social-ico"
                  >
                    <img src={tidalIMG} alt="Tidal" />
                  </a> */}
                  {user.facebook_url !== "" && (
                    <a
                      href={formatLink(user.facebook_url)}
                      target="_blank"
                      rel="noreferrer"
                      className="social-ico"
                    >
                      <img src={facebookIMG} alt="Facebook" />
                    </a>
                  )}
                  {/* <a
                    href="https://www.spotify.com"
                    target="_blank"
                    rel="noreferrer"
                    className="social-ico"
                  >
                    <img src={twitterIMG} alt="Twitter" />
                  </a>
                  <a
                    href="https://www.spotify.com"
                    target="_blank"
                    rel="noreferrer"
                    className="social-ico"
                  >
                    <img src={youtubeIMG} alt="Youtube" />
                  </a>
                  <a
                    href="https://www.spotify.com"
                    target="_blank"
                    rel="noreferrer"
                    className="social-ico"
                  >
                    <img src={tiktokIMG} alt="Tik Tok" />
                  </a>
                  <a
                    href="https://www.spotify.com"
                    target="_blank"
                    rel="noreferrer"
                    className="social-ico"
                  >
                    <img src={instagramIMG} alt="Instagram" />
                  </a> */}
                </div>
              </div>

              {user.bio !== "" && (
                <div className="profile-text">
                  <h3>My bio</h3>
                  <p>{user.bio}</p>
                </div>
              )}

              <div className="profile-text">
                <h3>Other links</h3>
                {user.facebook_url !== "" && (
                  <p>
                    <strong>Facebook:</strong>{" "}
                    <a
                      href={formatLink(user.facebook_url)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatLink(user.facebook_url)}
                    </a>
                  </p>
                )}
                {user.web_url !== "" && (
                  <p>
                    <strong>Web:</strong>{" "}
                    <a
                      href={formatLink(user.web_url)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatLink(user.web_url)}
                    </a>
                  </p>
                )}
                {/*{user.contract !== null && (*/}
                {/*  <p>*/}
                {/*    <strong>Metadata:</strong>{" "}*/}
                {/*    <a*/}
                {/*      href={"https://metadata.gram180.com/?artist=" + user.id}*/}
                {/*      target="_blank"*/}
                {/*      rel="noreferrer"*/}
                {/*    >*/}
                {/*      Open metadata*/}
                {/*    </a>*/}
                {/*  </p>*/}
                {/*)}*/}

                {user.contract !== null && (
                  <p>
                    <strong>Contract transaction ID:</strong>{" "}
                    <a
                      href={POLYGONSCAN_URL + "/address/" + user.contract}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Show on polygonscan.com
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      {showProfileModal && (
        <div className={"modal-window show"}>
          <div className="modal-content">
            <div className="modal-heading">
              <h1>Edit info</h1>
              <button
                onClick={() => setShowProfileModal(false)}
                className="modal-close"
              ></button>
            </div>

            <div>
              <ProfileForm
                type={"update"}
                userno={userno}
                useremail={useremail}
                usertoken={usertoken}
              />
            </div>
          </div>
        </div>
      )}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Footer />
    </>
  );
}
