import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import Action from "../components/Action";

import { ApplicationState, onLoading, loadUser } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL, DISTRO_URL, getImage } from "../utils";
import { sha256 } from "js-sha256";
import { toast } from "react-toastify";
import AlertMint from "../components/AlertMint";
import axios from "axios";

import icoDashboard from "../assets/images/icoDashboard.svg";
import icoLogout from "../assets/images/icoLogout.svg";

export default function HomePage({ userno, useremail, usertoken }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state: ApplicationState) => state.userReducer);

  const [contractIsReady, setContractIsReady] = useState(false);
  const [paid, setPaid] = useState(true);

  useEffect(() => {
    if (typeof user.email === "undefined") {
      navigate("/login/logout");
    }
  }, [user]);

  useEffect(() => {
    if (user.status === "0") {
      const timeoutID = window.setTimeout(() => {
        loadUserDataBackground();
      }, 10000);

      return () => {
        clearTimeout(timeoutID);
      };
    }
  }, [user]);

  const loadUserDataBackground = async () => {
    await dispatch(loadUser(user.id));
    const response = await axios.get<any>(
      `${BASE_URL}get_user_data.php?id=${user.id}&cache=${Date.now()}`
    );

    if (!response) {
    } else {
      if (response.data.status === "1") {
        setContractIsReady(true);
      }
    }
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="header-buttons">
                <button onClick={() => navigate("/home")} className="btn-left">
                  <img src={icoDashboard} alt="My Dashboard" />
                  <span>My Dashboard</span>
                </button>
                <div className="divider" />

                <button
                  onClick={() => navigate("/dispatch")}
                  className="btn-right"
                >
                  <span>Log out</span>
                  <img src={icoLogout} alt="Log out" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <h1 className="main-heading">{user.name}</h1>

      <div className="profile-photo-box">
        <img
          className="profile-photo"
          src={getImage(user.image, "th")}
          alt={user.name}
        />
      </div>

      <main className="container main-big">
        <div className="row">
          <div className="col-sm-12">
            <div className="content content-main not-flex">
              {user.status === "1" && (
                <>
                  <div className="center">
                    <button
                      className="btn-black"
                      onClick={() => navigate("/mints/1")}
                    >
                      Press a new NFT
                    </button>
                  </div>
                  <br />
                </>
              )}
              {user.status === "0" && (
                <>
                  <br />
                  <br />
                  <AlertMint
                    type="blue"
                    title="Generating smart contract"
                    desc="Your collection is being generated on the blockchain. This process can take a couple of minutes. As soon as it’s ready, you can create your first NFT!"
                  />
                </>
              )}
              {contractIsReady && (
                <>
                  <br />
                  <br />
                  <AlertMint type="green" title="Smart contract is ready!" />
                </>
              )}
              {user.status === "2" && (
                <>
                  <br />
                  <br />
                  <AlertMint
                    type="red"
                    title="Unexpected error generating a collection"
                    desc="There has been an issue creating your collection. Please try again later or contact us."
                  />
                </>
              )}

              {/* <Alert
                type="red"
                title="Holy guacamole!"
                desc="You should check in on some of those fields below."
              />
              <Alert
                type="green"
                title="Holy guacamole!"
                desc="You should check in on some of those fields below."
              /> */}
              <div className="btn-two">
                <div className="btn-box">
                  {user.status === 0 && (
                    <Action
                      type="mints"
                      text="Show my Mints"
                      onClick={() =>
                        toast.warn(
                          "Your collection is being generated on the blockchain. This process can take a couple of minutes. As soon as it’s ready, you can create your first NFT!"
                        )
                      }
                    />
                  )}
                  {user.status === 1 && (
                    <Action
                      type="mints"
                      text="Show my Mints"
                      onClick={() => navigate("/mints")}
                    />
                  )}
                </div>
                <div className="btn-box">
                  <Action
                    type="profile"
                    text="My profile"
                    onClick={() => navigate("/profile")}
                  />
                </div>
                {/*{(useremail === "vojta@vojtapohunek.com" ||*/}
                {/*  useremail === "romansimr11@gmail.com" ||*/}
                {/*  useremail === "b4rt11@gmail.com") && (*/}
                {/*  <div className="btn-box">*/}
                {/*    <Action*/}
                {/*      type="admin"*/}
                {/*      text="Admin"*/}
                {/*      onClick={() => navigate("/admin")}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}
