import axios from "axios";
import { sha256 } from "js-sha256";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import { BASE_URL, DISTRO_URL } from "../utils";
import { useNavigate } from "react-router-dom";
import loadingIMG from "../assets/images/loading.svg";

import { ApplicationState, onLoading, loadUser, loadAccounts } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Alert from "../components/Alert";

import icoDashboard from "../assets/images/icoDashboard.svg";
import icoLogout from "../assets/images/icoLogout.svg";

export default function InitPage({ userno, useremail, usertoken }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const [paid, setPaid] = useState(true);

  const { loading } = useSelector(
    (state: ApplicationState) => state.loadingReducer
  );

  useEffect(() => {
    checkPayment();
  }, []);

  const checkPayment = async () => {
    // const response = await axios.get<any>(
    //   `${DISTRO_URL}/getPaymentStatus.php?userno=${userno}&useremail=${useremail}&token=${usertoken}`
    // );
    let response = {status: 200, data: {"status":"Free"}}; // mock data
    if (!response) {
    } else {
      setPaid(
        response.data.status === "Paid" || response.data.status === "Free"
          ? true
          : false
      );

      if (response.data.status === "Paid" || response.data.status === "Free") {
        loadAccountsData();
      }
    }
  };

  const loadAccountsData = async () => {
    await Promise.all([
      dispatch(onLoading(true)),
      dispatch(loadAccounts(userno,useremail)),
    ]);

    navigate("/accounts-page");
    return dispatch(onLoading(false));
  };

  // const loadMainArtists = async () => {
  //   try {
  //     let res = await axios({
  //       url: `${DISTRO_URL}getMainArtists.php?userno=${userno}&useremail=${useremail}&token=${usertoken}&cache=${Date.now()}`,
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     if (res.status === 200) {
  //     } else {
  //     }
  //   } catch (err) {}
  // };

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12"></div>
          </div>
        </div>
      </header>
      <main className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="content content-login">
              {!paid && (
                <>
                  <Alert
                    type="red"
                    title="Holy guacamole!"
                    desc="You didn't pay, so you can't mint, bro."
                  />
                </>
              )}
              {paid && (
                <button
                  onClick={() => {}}
                  className="btn-black"
                  disabled={loading}
                >
                  Login with Papaya{" "}
                  {loading && (
                    <img
                      src={loadingIMG}
                      alt="Loading"
                      width={20}
                      height={20}
                      className="loading-animation"
                      style={{ position: "absolute", right: 60, top: 10 }}
                    />
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}
